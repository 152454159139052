<template>
    <div>
        <H2>About </H2>
        <p class="uk-text-justify"> WellStar is a professional consumer goods design, development, sourcing,
            and logistics company for major retailers and brands around the world.</p>

        <p class="uk-text-justify">
            We specialize in responsibly managing supply chains of high-volume, time-sensitive
            goods. We provide sophisticated, one-stop-shop solutions to meet our customers’
            specific needs.  We are committed to achieving the highest standards when meeting
            the needs of our customers through competitive pricing, quality, and reliable delivery.
        </p>
        <p class="uk-text-justify">
            By leveraging our global network, market knowledge, and advanced technology, we have the
            capacity and flexibility to rapidly respond to evolving trends in consumer and production
            markets.
        </p>
        <p class="uk-text-justify">
            Our business is built upon our people, our time-honored values, our approach to
            sustainability through the entire supply chain, and our community engagement.
            Our global network, our financial strength, and our leadership in change are the
            platform for our continued success and future growth.
        </p>
    </div>
</template>

<script>
    export default {
        name: "About",
        data: () => ({
            title: "About"
        }),
    }
</script>

<style scoped>

</style>
